<template>
  <div
    v-if="isFormReady"
    class="ats-form"
    @click="
      updateSavingStatus('not_changed'), updateValidationStatus('not_validated')
    "
  >
    <AtsFormBlock v-if="metaDiary">
      <h1 class="tw-leading-25 ats-form-header">
        {{ "ATS Keskus" }}: {{ metaDiary.address }}
      </h1>
      <h2 class="m-0 tw-text-15-bold">
        {{ formDate }}
      </h2>
      <AtsFormHeader />
      <AtsFormInputTime
        class="ats-form-time-start"
        :placeholder="$t('Alguse kellaaeg')"
        stateKey="start_date"
      />
    </AtsFormBlock>
    <AtsFormDivider />
    <AtsFormBlock>
      <AtsFormHeader2 :text="$t('1. Signaaliedastuse kontroll')" />
      <AtsFormRow><AtsFormSelect /></AtsFormRow>
      <AtsFormRow>
        <AtsFormYesNo
          text="Signaal edastub Häirekeskusesse"
          state-key="alarm_received_in_hairekeskus"
          commit="updateSection1"
        />
        <AtsFormYesNo
          text="Signaal edastub turvaettevõtte häirekeskusesse"
          state-key="alarm_received_in_security_company"
          commit="updateSection1"
        />
      </AtsFormRow>
    </AtsFormBlock>
    <AtsFormDivider />
    <AtsFormBlock>
      <AtsFormHeader2 :text="$t('2. Kontrollitoimingud')" />
      <AtsFormRow>
        <template v-if="isFormAnnual">
          <AtsFormYesNo
            text="Keskseade eristab katkestust andurite ahelas"
            state-key="central_device_distinguishes_between_an_interruption_in_the_sensor_circuit"
            commit="updateSection2"
          />
          <AtsFormYesNo
            text="Keskseade eristab lühist andurite ahelas"
            state-key="central_device_distinguishes_between_short_circuits_in_the_sensor_circuit"
            commit="updateSection2"
          />
          <AtsFormYesNo
            text="Keskseade eristab katkestust alarmahelas"
            state-key="central_device_distinguishes_between_an_interruption_in_the_alarm_circuit"
            commit="updateSection2"
          />
          <AtsFormYesNo
            text="Keskseade eristab lühist alarmahelas"
            state-key="central_device_distinguishes_between_short_circuits_in_the_alarm_circuit"
            commit="updateSection2"
          />
        </template>

        <AtsFormYesNo
          text="Keskseade eristab põhitoite katkestust"
          state-key="it_can_determine_main_power_source"
          commit="updateSection2"
        />
        <AtsFormYesNo
          text="Keskseade eristab varutoite katkestust"
          state-key="it_can_determine_aux_power_source"
          commit="updateSection2"
        />
        <AtsFormYesNo
          text="Kaablite ühendused on korras ja kaitstud"
          state-key="wires_are_connected_and_protected_securely"
          commit="updateSection2"
        />
        <AtsFormYesNo
          text="Alarmseadmed on töökorras"
          state-key="alarm_devices_are_working_correctly"
          commit="updateSection2"
        />

        <AtsFormYesNo
          text="Andur või tulekahjuteatenupp rakenduvad kõikides ahelates"
          state-key="sensor_or_fire_alarm_button_are_working_in_all_circuits"
          commit="updateSection2"
        />

        <AtsFormYesNo
          text="ATSi seadmed on väliselt korras ja seadmetel ei ole märke niiskusest (vaatluse tulemus)"
          state-key="devices_are_in_good_condition_according_to_visual"
          commit="updateSection2"
        />
        <AtsFormYesNo
          text="ATSi asendiplaanid, paiknemisskeemid, kasutusjuhendid ja hoolduspäevik on ühes hoidekohas ja see hoidekoht on tähistatud"
          state-key="device_schemes_are_in_one_place_and_place_is_identifiable"
          commit="updateSection2"
        />
        <AtsFormYesNo
          :text="
            isFormAnnual
              ? 'ATS on hooldatud hooldusjuhendi kohaselt'
              : 'ATS on hooldusjuhendi kohaselt hooldatud'
          "
          state-key="device_is_maintained_using_original_manuals"
          commit="updateSection2"
        />

        <AtsFormYesNo
          text="Sissekanded ATSi hoolduspäevikuse on tehtud"
          state-key="device_is_maintained_correctly_before"
          commit="updateSection2"
        />
        <template v-if="isFormAnnual">
          <AtsFormYesNo
            text="ATSi kasutamise täiendväljaõpe on toimunud"
            state-key="further_training_in_the_use_of_ats_has_taken_place"
            commit="updateSection2"
          />
          <AtsFormYesNo
            text="Juurdepääs kõikidele ruumidele on tagatud"
            state-key="access_to_all_rooms_is_guaranteed"
            commit="updateSection2"
          />
        </template>
        <AtsFormYesNo
          text="ATS keskseadmele ja tulekahjuteatenuppudele on tagatud vaba ligipääs"
          state-key="all_devices_are_accessible_freely"
          commit="updateSection2"
        />
      </AtsFormRow>
      <AtsFormRow single>
        <AtsFormTextarea
          :placeholder="$t('Märkused')"
          state-key="second_section_remarks"
          commit="updateSection2"
        />
      </AtsFormRow>
      <AtsFormHeader3 :text="$t('Valikulised kontrollitoimungud')" />
      <AtsFormRow>
        <AtsFormYesNo
          text="Ventilatsiooni automaatne juhtimine on töökorras"
          state-key="ventilation_automatics_are_working_correctly"
          commit="updateSection2Optional"
        />
        <AtsFormYesNo
          text="Liftide automaatne juhtimine on töökorras"
          state-key="elevator_automatics_are_working_correctly"
          commit="updateSection2Optional"
        />
        <AtsFormYesNo
          text="Tuletõkkesuste automaatne juhtimine on töökorras"
          state-key="fire_door_automatics_are_working_correctly"
          commit="updateSection2Optional"
        />
        <AtsFormYesNo
          text="Suitsueemaldusseadmete automaatne rakendumine on töökorras"
          state-key="smoke_extraction_automatics_are_working_correctly"
          commit="updateSection2Optional"
        />
        <AtsFormYesNo
          text="Evakuatsiooni- ja väljapääsuuste automaatne juhtimine on töökorras"
          state-key="evacuation_and_exit_door_automatics_are_working_correctly"
          commit="updateSection2Optional"
        />
      </AtsFormRow>
    </AtsFormBlock>
    <AtsFormDivider />
    <AtsFormBlock>
      <AtsFormHeader2 :text="$t('3. Hooldatud seadmed')" />
      <template v-for="(device, key) of section3Devices">
        <AtsFormDevices :key="`device_${key}`" :name="key" />
        <AtsFormDivider
          v-if="key + 1 < section3Devices.length"
          :key="`device_divider_${key}`"
          mobileOnly
        />
      </template>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <AtsFormButtonAdd v-if="!isConfirmed" @click="addSection3Device">
          {{ $t("log.btn.addDevice") }}
        </AtsFormButtonAdd>
        <AtsFormErrors errorKey="devices" class="mt-1" />
      </div>
      <AtsFormHeader3 :text="$t('Reservtoiteaku')" />
      <AtsFormHeader3 :text="$t('Süsteemi voolutarve reservtoiteakudelt')" v-if="isFormAnnual" />
      <div v-if="isFormAnnual" style="display: flex;">
        <AtsFormInputNumber stateKey="normal_reserve_voltage" commit="updateSection3" text="Normaalolekus" suffix="mA"/>
        <AtsFormInputNumber stateKey="fault_reserve_voltage" commit="updateSection3" text="Häireolekus" suffix="mA"/>
        <AtsFormInputNumber stateKey="charge_voltage" commit="updateSection3" text="Laadimispinge" suffix="V"/>
      </div>
      <AtsFormBatteries />
      <div class="d-flex justify-content-center">
        <AtsFormButtonAdd v-if="!isConfirmed" @click="addSection3AuxBattery">
          {{ $t("log.btn.addAuxBattery") }}
        </AtsFormButtonAdd>
      </div>
      <AtsFormRow>
        <AtsFormYesNo
          text="Reservtoiteakude mahtuvus vastab nõuetele"
          state-key="aux_power_amp_hour_sufficient"
          commit="updateSection3"
        />
        <AtsFormYesNo
          text="Paigaldatud uued akud"
          state-key="new_batteries_installed"
          commit="updateSection3"
        />
      </AtsFormRow>
    </AtsFormBlock>
    <AtsFormDivider />
    <AtsFormBlock>
      <AtsFormHeader2 :text="$t('4. Hooldaja märkused')" />
      <AtsFormRow>
        <AtsFormYesNo
          text="ATSi hooldus on lõpetanud"
          state-key="ats_maintenance_successful"
          commit="updateSection4"
        />
        <AtsFormYesNo
          text="Süsteem on viidud valveolekusse"
          state-key="ats_out_of_test_mode"
          commit="updateSection4"
        />
        <AtsFormYesNo
          text="Hoolduse käigus on muudetud seadistus"
          state-key="ats_configuration_changed"
          commit="updateSection4"
        />
      </AtsFormRow>
      <AtsFormRow column>
        <AtsFormTextarea
          :placeholder="$t('Kui vastus on “jah” , palun kirjeldage muudatust')"
          state-key="ats_configuration_changed_text"
          commit="updateSection4"
        />
        <AtsFormTextarea
          :placeholder="
            $t(
              'Palun märkige, kui hoones on tehtud ehitustöid, mille tõttu on ATS süsteemis vaja teha täendusi või muudatusi. Palun kirjeldage, mida on tehtud'
            )
          "
          state-key="general_remarks"
          commit="updateSection4"
        />
      </AtsFormRow>
    </AtsFormBlock>
    <AtsFormDivider />
    <AtsFormBlock>
      <AtsFormHeader2 :text="$t('5. Tellija märkused')" />
      <AtsFormRow single>
        <AtsFormTextarea
          :placeholder="$t('Märkused')"
          state-key="customer_remarks"
          commit="updateSection5"
        />
      </AtsFormRow>
    </AtsFormBlock>
    <AtsFormDivider />
    <AtsFormBlock :gap="false">
      <AtsFormRow class="ats-form-section-last-headers" desktop>
        <AtsFormHeader2 :text="$t('6. Hoolduse teinud isiku(te) andmed')" />
        <AtsFormHeader2
          :text="$t('7. Tellija või tema esindaja andmed (tööde vastuvõtmine)')"
        />
      </AtsFormRow>
      <AtsFormRow column>
        <AtsFormColumn>
          <AtsFormHeader2
            :text="$t('6. Hoolduse teinud isiku(te) andmed')"
            mobile
          />
          <div class="ats-form-section-last">
            <AtsFormInputDate
              placeholder="Kuupäev"
              state-key="tech_date"
              commit="updateSignatures"
            />
            <AtsFormInputText
              placeholder="Ees- ja perekonnanimi"
              state-key="tech_fullname"
              commit="updateSignatures"
            />
            <AtsFormInputText
              placeholder="Kutsetunnistuse number"
              state-key="tech_professional_certificate_number"
              commit="updateSignatures"
            />
          </div>
          <AtsFormInputTime
            :placeholder="$t('Lõpu kellaaeg')"
            stateKey="end_date"
          />
          <AtsFormSignature v-if="isFormReady" state-key="tech_signature" />
        </AtsFormColumn>
        <AtsFormDivider mobileOnly />
        <AtsFormColumn>
          <AtsFormHeader2
            :text="
              $t('7. Tellija või tema esindaja andmed (tööde vastuvõtmine)')
            "
            mobile
          />
          <div class="ats-form-section-last">
            <AtsFormInputDate
              placeholder="Kuupäev"
              state-key="client_date"
              commit="updateSignatures"
            />
            <AtsFormInputText
              placeholder="Ees- ja perekonnanimi"
              state-key="client_fullname"
              commit="updateSignatures"
            />
            <AtsFormInputText
              placeholder="epost@aadress.com"
              type="email"
              state-key="client_email"
              commit="updateSignatures"
            />
          </div>
          <AtsFormSignature v-if="isFormReady" state-key="client_signature" />
        </AtsFormColumn>
      </AtsFormRow>
    </AtsFormBlock>
    <AtsFormBlock class="ats-form-footer" :gap="false">
      <div class="ats-form-footer__container">
        <AtsFormFooterButton back>
          {{ "Tagasi" }}
        </AtsFormFooterButton>
      </div>
      <div class="ats-form-footer__container justify-content-between">
        <AtsFormFooterButton
          v-if="!isConfirmed && authorized"
          :primary="true"
          @click="save"
        >
          {{ "Salvesta" }}
        </AtsFormFooterButton>
        <AtsFormFooterButton v-if="!isConfirmed && authorized" @click="confirm">
          {{ "Kinnita" }}
        </AtsFormFooterButton>
        <div
          v-if="isSaveFulfilled || isSaveRejected || isValidatedRejected"
          class="ats-form-footer__container ats-form-footer__container--save"
          :class="{
            'ats-form-footer__container--save-sucess': isSaveFulfilled,
          }"
        >
          <span v-if="isSaveFulfilled" class="tw-text-15">
            {{ "Hoolduse akt on edukalt salvestatud!" }}
          </span>
          <span v-if="isSaveRejected" class="tw-text-15">
            {{
              "Hoolduse akti salvestamine ebaõnnestus. Palun proovige uuesti."
            }}
          </span>
          <span v-if="isValidatedRejected" class="tw-text-15">
            {{
              "Hoolduse akti kinnitamine ebaõnnestus. Palun täidke kõik kohustulikud väljad."
            }}
          </span>
        </div>
      </div>
    </AtsFormBlock>
  </div>
  <AppLoader v-else />
</template>

<script>
import "@/scss/theme-default.scss";
import "@/scss/tw/tw.scss";

import productApi from "@/api/modules/product.js";
import AppLoader from "@/components/AppLoader.vue";
import AtsFormBatteries from "@/components/AtsFormBatteries.vue";
import AtsFormBlock from "@/components/AtsFormBlock.vue";
import AtsFormColumn from "@/components/AtsFormColumn.vue";
import AtsFormDevices from "@/components/AtsFormDevices.vue";
import AtsFormDivider from "@/components/AtsFormDivider.vue";
import AtsFormFooterButton from "@/components/AtsFormFooterButton.vue";
import AtsFormHeader from "@/components/AtsFormHeader.vue";
import AtsFormHeader2 from "@/components/AtsFormHeader2.vue";
import AtsFormHeader3 from "@/components/AtsFormHeader3.vue";
import AtsFormInputDate from "@/components/AtsFormInputDate.vue";
import AtsFormInputText from "@/components/AtsFormInputText.vue";
import AtsFormInputTime from "@/components/AtsFormInputTime.vue";
import AtsFormInputNumber from "@/components/AtsFormInputNumber.vue";
import AtsFormRow from "@/components/AtsFormRow.vue";
import AtsFormSelect from "@/components/AtsFormSelect.vue";
import AtsFormSignature from "@/components/AtsFormSignature.vue";
import AtsFormTextarea from "@/components/AtsFormTextarea.vue";
import AtsFormYesNo from "@/components/AtsFormYesNo.vue";
import { utilityProductMeta } from "@/utils/utility.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

const AtsFormButtonAdd = () => import("@/components/AtsFormButtonAdd.vue");
const AtsFormErrors = () => import("@/components/AtsFormErrors.vue");

export default {
  components: {
    AppLoader,
    AtsFormBlock,
    AtsFormColumn,
    AtsFormDivider,
    AtsFormFooterButton,
    AtsFormHeader,
    AtsFormHeader2,
    AtsFormHeader3,
    AtsFormInputDate,
    AtsFormInputText,
    AtsFormInputTime,
    AtsFormInputNumber,
    AtsFormRow,
    AtsFormBatteries,
    AtsFormDevices,
    AtsFormSignature,
    AtsFormTextarea,
    AtsFormYesNo,
    AtsFormButtonAdd,
    AtsFormSelect,
    AtsFormErrors,
  },
  data() {
    return {
      product: undefined,
      meta: undefined,
    };
  },
  computed: {
    ...mapState("atsForm", ["formDataSection3"]),
    ...mapGetters(["authorized"]),
    ...mapGetters("atsForm", [
      "formDate",
      "section3Devices",
      "isFormReady",
      "isSaveFulfilled",
      "isSaveRejected",
      "isConfirmed",
      "isValidatedRejected",
    ]),
    isFormAnnual() {
      return this.$route.name === "AtsFormANNUAL";
    },
    metaDiary() {
      if (!this.meta || this.meta.length === 0) {
        return { address: "Aadress" };
      }

      const diary = utilityProductMeta.selectGroup(this.meta, "Hoolduspäevik");

      if (diary === undefined) {
        return { address: "Aadress" };
      }

      const address = utilityProductMeta.selectMeta(diary, "Aadress");

      return { address: address ? address.value : "Aadress" };
    },
  },
  methods: {
    ...mapMutations("atsForm", [
      "updateSavingStatus",
      "updateValidationStatus",
      "addSection3Device",
      "addSection3AuxBattery",
    ]),
    ...mapActions("atsForm", ["addSection3Row", "save", "confirm"]),
    async fetchProduct() {
      const res = await productApi.getProduct({
        tenantId: this.$route.params.tenantId,
        uuid: this.$route.params.uuid,
      });
      this.product = res.data.data.product;
      this.meta = res.data.data.meta;
    },
  },
  beforeMount() {
    this.fetchProduct();

    this.$store.dispatch("atsForm/restore", {
      logId: this.$route.params.logId,
      entryId: this.$route.params.entryId,
    });
    this.$store.commit("rootSetSelectedEntryId", this.$route.params.entryId);
  },
  destroyed() {
    this.$store.commit("atsForm/resetStore");
  },
};
</script>

<style lang="scss">
.ats-form {
  background: #fbfbfb !important;
  border: 1px solid #e4e8ef !important;
  display: flex;
  flex-flow: column;
  gap: 24px;
  margin: 0 auto !important;
  max-width: 1008px !important;
  padding: 15px 0 !important;
}

.ats-form-header {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.ats-form-container {
  display: flex;
  flex-flow: column;
  padding: 0 15px !important;
}

.ats-form-section-last {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.ats-form-section-last-headers {
  margin-bottom: 24px !important;
}

.ats-form-footer {
  display: flex;
  flex-direction: column-reverse !important;
  margin-top: 42px !important;
  gap: 24px;
}

.ats-form-footer__container {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  gap: 18px;
  justify-content: flex-end;
}

.ats-form-footer__container--save {
  flex: 1 1 100% !important;
  gap: 0 !important;
}

.ats-form-footer__container--save-sucess::before {
  content: "";
  width: 12px;
  margin-right: 9px;
  background: no-repeat center
    url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM3.6 4.8L2.4 6L5.4 9L9.6 4.8L8.4 3.6L5.4 6.6L3.6 4.8Z' fill='%2373C088'/%3E%3C/svg%3E%0A");
}

.ats-form-mt-18 {
  margin-top: 18px !important;
}

.ats-form-mt-35 {
  margin-top: 35px !important;
}

.ats-form-mt-22 {
  margin-top: 22px !important;
}

@media screen and (min-width: 764px) {
  .ats-form {
    padding: 32px 0 !important;
  }

  .ats-form-container {
    padding: 0 32px !important;
  }

  .ats-form-time-start {
    width: 47% !important;
  }

  .ats-form-footer {
    flex-direction: row !important;
    justify-content: space-between;
  }

  .ats-form-footer__container {
    display: flex;
    flex: 0 1 47%;
    justify-content: flex-start;
  }
}
</style>
