<template>
  <div class="ats-form-battery-flex" style="display: flex; justify-content: space-around; align-items: center;">
    {{ text }}
    <div class="ats-form-battery__input-container">
      <input
          type="number"
          :placeholder="placeholder"
          v-model="model"
          class="tw-color-main tw-color-main-placeholder ats-form-battery__input"
          :disabled="isConfirmed"
      />
      <span>{{ suffix }}</span>
    </div>
    <AtsFormErrors :errorKey="errorKey" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AtsFormErrors from "@/components/AtsFormErrors.vue";

export default {
  components: {
    AtsFormErrors,
  },
  props: {
    stateKey: {
      required: true,
    },
    suffix: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String
    },
    commit: {
      type: String,
      required: true,
    },
    placeholder: String,
    useErrorKey: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters("atsForm", ["storeKeyValue", "isConfirmed"]),
    model: {
      get() {
        return this.storeKeyValue(this.commit, this.stateKey);
      },
      set(value) {
        this.$store.commit(`atsForm/${this.commit}`, {
          key: this.stateKey,
          value,
        });
      },
    },
    errorKey() {
      return this.useErrorKey || this.stateKey;
    },
  },
};
</script>

<style scoped>
.ats-form-battery__input {
  align-items: center;
  background-color: #e4e8ef;
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  max-height: 49px !important;
  outline-width: 0px !important;
  max-width: 78px;
}

.ats-form-battery__input::-webkit-outer-spin-button,
.ats-form-battery__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ats-form-battery__input[type="number"] {
  -moz-appearance: textfield;
}

.ats-form-battery__input-container {
  align-items: center;
  background-color: #e4e8ef;
  border-radius: 4px !important;
  border: 1px solid #d4d9e2;
  display: flex;
  flex: 0 1;
  gap: 8px;
  justify-content: space-between;
  max-height: 49px !important;
  padding: 14px;
}

</style>
