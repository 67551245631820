import connectionLog from "@/api/modules/log.js";
import { app } from "@/main.js";
import { addMinutes, format } from "date-fns";
import { get, has } from "lodash-es";
import Vue from "vue";

const formatDate = (date, time) => {
  const [day, month, year] = date.split(".");
  const [hours, minutes] = time.split(":");
  const newDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);

  return format(
    addMinutes(newDate, newDate.getTimezoneOffset()),
    "yyyy-MM-dd kk:mm"
  );
};

const selectData = (state) => {
  let data = {
    ...state.data.header,
    ...state.data.section1,
    ...state.data.section2,
    ...state.data.section3,
    ...state.data.section4,
    ...state.data.section5,
    ...state.data.signatures,
    confirmed_at: state.data.confirmed_at,
  };

  if (state.data.start_date) {
    data.start_date = formatDate(state.data.form_date, state.data.start_date);
  }

  if (state.data.end_date) {
    data.end_date = formatDate(state.data.form_date, state.data.end_date);
  }

  data.type = app.$route.name === "AtsFormANNUAL" ? "A" : "K";

  return data;
};

const stateDefault = () => {
  return {
    formStatus: "start",
    savingStatus: "not_changed",
    validationStatus: "not_validated",
    ids: {},
    data: {
      form_date: format(new Date(), "dd.MM.yyyy"),
      start_date: undefined,
      end_date: undefined,
      confirmed_at: null,
      header: {
        customer_name: undefined,
        contact_person: undefined,
        contact_person_number: undefined,
        maintenance_contract_number: undefined,
        building_address: undefined,
        building_name: undefined,
      },
      section1: {
        ats_type: null,
        alarm_received_in_hairekeskus: undefined,
        alarm_received_in_security_company: undefined,
      },
      section2: {
        central_device_distinguishes_between_an_interruption_in_the_sensor_circuit:
          undefined,
        central_device_distinguishes_between_short_circuits_in_the_sensor_circuit:
          undefined,
        central_device_distinguishes_between_an_interruption_in_the_alarm_circuit:
          undefined,
        central_device_distinguishes_between_short_circuits_in_the_alarm_circuit:
          undefined,
        it_can_determine_main_power_source: undefined,
        it_can_determine_aux_power_source: undefined,
        wires_are_connected_and_protected_securely: undefined,
        alarm_devices_are_working_correctly: undefined,
        sensor_or_fire_alarm_button_are_working_in_all_circuits: undefined,
        devices_are_in_good_condition_according_to_visual: undefined,
        device_schemes_are_in_one_place_and_place_is_identifiable: undefined,
        device_is_maintained_using_original_manuals: undefined,
        device_is_maintained_correctly_before: undefined,
        further_training_in_the_use_of_ats_has_taken_place: undefined,
        access_to_all_rooms_is_guaranteed: undefined,
        all_devices_are_accessible_freely: undefined,
        second_section_remarks: "",
        optional: {
          ventilation_automatics_are_working_correctly: undefined,
          elevator_automatics_are_working_correctly: undefined,
          fire_door_automatics_are_working_correctly: undefined,
          smoke_extraction_automatics_are_working_correctly: undefined,
          evacuation_and_exit_door_automatics_are_working_correctly: undefined,
        },
      },
      section3: {
        devices: [
          {
            is_maintained: false,
            name: "",
            quantity: "",
            remark: "",
            is_tested: false,
            brand: "",
          },
        ],
        aux_batteries: [
          {
            name: "",
            amp_hour: undefined,
            volt: undefined,
          },
        ],
        aux_power_amp_hour_sufficient: undefined,
        new_batteries_installed: undefined,
        normal_reserve_voltage: undefined,
        fault_reserve_voltage: undefined,
        charge_voltage: undefined,
      },
      section4: {
        ats_maintenance_successful: undefined,
        ats_out_of_test_mode: undefined,
        ats_configuration_changed: undefined,
        ats_configuration_changed_text: "",
        general_remarks: "",
      },
      section5: {
        customer_remarks: "",
      },
      signatures: {
        tech_date: undefined,
        tech_fullname: "",
        tech_professional_certificate_number: "",
        tech_signature: "",
        client_date: undefined,
        client_fullname: "",
        client_email: "",
        client_signature: "",
      },
    },
    errors: {},
  };
};

const state = stateDefault();

export default {
  namespaced: true,

  state,

  getters: {
    formDate: (state) => {
      return state.data.form_date;
    },
    time: (state) => (type) => {
      return state.data[type];
    },
    section3Devices: (state) => {
      return state.data.section3.devices;
    },
    section3Device: (state) => (key) => {
      return state.data.section3[key];
    },
    section3AuxBatteries: (state) => {
      return state.data.section3.aux_batteries;
    },
    section3AuxBattery: (state) => (key) => {
      return state.data.section3.aux_batteries[key];
    },
    storeKeyValue: (state) => (commit, key) => {
      switch (commit) {
        case "updateHeader":
          return state.data.header[key];

        case "updateSection1":
          return state.data.section1[key];

        case "updateSection2":
          return state.data.section2[key];

        case "updateSection2Optional":
          return state.data.section2.optional[key];

        case "updateSection3":
          return state.data.section3[key];

        case "updateSection3Name":
          return state.data.section3.devices[key].name;

        case "updateSection3Testing":
          return state.data.section3.devices[key].is_tested;

        case "updateSection3Maintained":
          return state.data.section3.devices[key].is_maintained;

        case "updateSection3Remark":
          return state.data.section3.devices[key].remark;

        case "updateSection3Brand":
          return state.data.section3.devices[key].brand;

        case "updateSection3Quantity":
          return state.data.section3.devices[key].quantity;

        case "updateSection3AuxBatteryName":
          return state.data.section3.aux_batteries[key].name;

        case "updateSection3AuxBatteryAmpHour":
          return state.data.section3.aux_batteries[key].amp_hour;

        case "updateSection3AuxBatteryVolt":
          return state.data.section3.aux_batteries[key].volt;

        case "updateSection4":
          return state.data.section4[key];

        case "updateSection5":
          return state.data.section5[key];

        case "updateSignatures":
          return state.data.signatures[key];

        default:
          break;
      }
    },
    getFieldErrors: (state) => (key) => {
      return has(state.errors, key, false) ? "Kohustuslik" : "";
    },
    isFormReady: (state) => {
      return state.formStatus === "ready";
    },
    isSaveFulfilled: (state) => {
      return state.savingStatus === "fulfilled";
    },
    isSaveRejected: (state) => {
      return state.savingStatus === "rejected";
    },
    isValidated: (state) => {
      return state.validationStatus === "validated";
    },
    isValidatedRejected: (state) => {
      return state.validationStatus === "rejected";
    },
    isConfirmed: (state) => {
      return !!state.data.confirmed_at;
    },
  },

  mutations: {
    addSection3Device(state) {
      const newDeviceData = {
        brand: "",
        is_maintained: false,
        is_tested: false,
        name: "",
        quantity: "",
        remark: "",
      };

      if (state.data.section3.devices) {
        const nextIndex = state.data.section3.devices.length;
        Vue.set(state.data.section3.devices, nextIndex, newDeviceData);
      } else {
        Vue.set(state.data.section3, "devices", [newDeviceData]);
      }
    },
    addSection3AuxBattery(state) {
      const nextIndex = state.data.section3.aux_batteries.length;
      Vue.set(state.data.section3.aux_batteries, nextIndex, {
        name: "",
        amp_hour: undefined,
        volt: undefined,
      });
    },
    addErrors(state, errors) {
      Vue.set(state, "errors", errors);
    },
    updateIds(state, payload) {
      Vue.set(state, "ids", payload);
    },
    updateTime(state, { key, value }) {
      Vue.set(state.data, key, value);
    },
    updateHeader(state, { key, value }) {
      Vue.set(state.data.header, key, value);
    },
    updateSection1(state, { key, value }) {
      Vue.set(state.data.section1, key, value);
    },
    updateSection2(state, { key, value }) {
      Vue.set(state.data.section2, key, value);
    },
    updateSection2Optional(state, { key, value }) {
      Vue.set(state.data.section2.optional, key, value);
    },
    updateSection3(state, { key, value }) {
      Vue.set(state.data.section3, key, value);
    },
    updateSection3Name(state, { key, value }) {
      Vue.set(state.data.section3.devices[key], "name", value);
    },
    updateSection3Brand(state, { key, value }) {
      Vue.set(state.data.section3.devices[key], "brand", value);
    },
    updateSection3Quantity(state, { key, value }) {
      Vue.set(state.data.section3.devices[key], "quantity", value);
    },
    updateSection3Testing(state, { key, value }) {
      Vue.set(state.data.section3.devices[key], "is_tested", value);
    },
    updateSection3Maintained(state, { key, value }) {
      Vue.set(state.data.section3.devices[key], "is_maintained", value);
    },
    updateSection3Remark(state, { key, value }) {
      Vue.set(state.data.section3.devices[key], "remark", value);
    },
    updateSection3AuxBatteryName(state, { key, value }) {
      Vue.set(state.data.section3.aux_batteries[key], "name", value);
    },
    updateSection3AuxBatteryAmpHours(state, { key, value }) {
      Vue.set(state.data.section3.aux_batteries[key], "amp_hour", value);
    },
    updateSection3AuxBatteryVolt(state, { key, value }) {
      Vue.set(state.data.section3.aux_batteries[key], "volt", value);
    },
    updateSection4(state, { key, value }) {
      Vue.set(state.data.section4, key, value);
    },
    updateSection5(state, { key, value }) {
      Vue.set(state.data.section5, key, value);
    },
    updateSignatures(state, { key, value }) {
      Vue.set(state.data.signatures, key, value);
    },
    updateFormStatus(state, status) {
      Vue.set(state, "formStatus", status);
    },
    updateSavingStatus(state, value = "not_changed") {
      Vue.set(state, "savingStatus", value);
    },
    updateValidationStatus(state, value) {
      Vue.set(state, "validationStatus", value);
    },
    deleteDevice(state, index) {
      Vue.delete(state.data.section3.devices, index);
    },
    deleteBattery(state, index) {
      Vue.delete(state.data.section3.aux_batteries, index);
    },
    restore(state, data) {
      if (has(data, "start_date") && data.start_date) {
        const date = new Date(data.start_date);
        Vue.set(state.data, "form_date", format(date, "dd.MM.yyyy"));
        Vue.set(state.data, "start_date", format(date, "HH:mm:ss"));
      } else {
        Vue.set(state.data, "form_date", format(new Date(), "dd.MM.yyyy"));
      }

      if (has(data, "end_date") && data.end_date) {
        Vue.set(
          state.data,
          "end_date",
          format(new Date(data.end_date), "HH:mm:ss")
        );
      }

      if (has(data, "confirmed_at")) {
        Vue.set(state.data, "confirmed_at", data.confirmed_at);
      }

      if (has(data, "header")) {
        Vue.set(state.data, "header", data.header);
      }

      if (has(data, "first_section_answers")) {
        Vue.set(state.data, "section1", data.first_section_answers);
      }

      if (has(data, "second_section_answers")) {
        Vue.set(state.data, "section2", data.second_section_answers);
      }

      if (has(data, "third_section_answers")) {
        Vue.set(state.data, "section3", data.third_section_answers);
      }

      if (has(data, "fourth_section_answers")) {
        Vue.set(state.data, "section4", data.fourth_section_answers);
      }

      if (has(data, "fifth_section_answers")) {
        Vue.set(state.data, "section5", data.fifth_section_answers);
      }

      if (has(data, "signatures")) {
        Vue.set(state.data, "signatures", data.signatures);
      }
    },
    restoreHeader(state, header) {
      Vue.set(state.data, "header", header);
    },
    restoreDevices(state, devices) {
      Vue.set(state.data.section3, "devices", devices);
    },
    restoreBatteries(state, batteries) {
      Vue.set(state.data.section3, "aux_batteries", batteries);
    },
    restoreSignatures(state, signatures) {
      Vue.set(state.data.signatures, "tech_fullname", signatures.tech_fullname);
      Vue.set(
        state.data.signatures,
        "tech_professional_certificate_number",
        signatures.tech_professional_certificate_number
      );
      Vue.set(
        state.data.signatures,
        "client_fullname",
        signatures.client_fullname
      );
      Vue.set(state.data.signatures, "client_email", signatures.client_email);
    },
    resetStore(state) {
      Object.assign(state, stateDefault());
    },
  },

  actions: {
    async restore({ commit, dispatch }, payload) {
      try {
        commit("addErrors", {});
        commit("updateIds", payload);
        const response = await connectionLog.get(
          payload.logId,
          payload.entryId
        );

        commit("restore", response.data);
        commit("updateFormStatus", "ready");
      } catch (error) {
        commit("resetStore");
        commit("updateIds", payload);

        try {
          await dispatch("prefillCustomer", payload.logId);
          await dispatch("prefillDevices", payload.logId);
        } catch (error) {
          console.error(error);
        } finally {
          commit("updateFormStatus", "ready");
        }
      }
    },
    async prefillCustomer({ commit }, logId) {
      const response = await connectionLog.getPrefilledCustomer(logId);
      commit("restoreHeader", get(response, "data.header", []));
      commit("restoreSignatures", get(response, "data.signatures", []));
    },
    async prefillDevices({ commit }, logId) {
      const response = await connectionLog.getPrefilledDevices(logId);
      commit("restoreDevices", get(response, "data.devices", []));
      commit("restoreBatteries", get(response, "data.aux_batteries", []));
    },
    async save({ state, commit }) {
      try {
        commit("updateSavingStatus", "pending");
        commit("addErrors", {});

        const response = await connectionLog.save(
          state.ids.logId,
          state.ids.entryId,
          selectData(state)
        );

        if (response.data.success) {
          commit("updateSavingStatus", "fulfilled");
        }
      } catch (error) {
        if (error.errors) {
          commit("addErrors", error.errors);
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        commit("updateSavingStatus", "rejected");
      }
    },
    async confirm({ state, commit }) {
      try {
        commit("updateValidationStatus", "pending");
        commit("addErrors", {});

        const response = await connectionLog.validate(
          state.ids.logId,
          state.ids.entryId,
          selectData(state)
        );

        if (response.data.success) {
          commit("updateValidationStatus", "validated");
          app.$router.push({ name: "LogList" });
        }
      } catch (error) {
        if (error.errors) {
          commit("addErrors", error.errors);
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        commit("updateValidationStatus", "rejected");
      }
    },
  },
};
